import React, { useContext, useEffect, useState } from "react"
import Layout from "@components/Layout/Layout"
import Container from "@components/Container/Container.jsx"
import MainWrap from "@components/Wrap/MainWrap"
import { Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Card from "@components/Cards/Card.jsx"
import { CardActionArea, CardMedia, CardContent } from "@material-ui/core"
import { graphql, navigate } from "gatsby"
import Chip from "@components/Cards/Chip.jsx"
import "date-fns"
import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
import { FormControlLabel, Checkbox } from "@material-ui/core"
import { makeComponentStyles } from "@components/mixins"
import { IntlContextConsumer, useIntl } from "gatsby-plugin-react-intl"
import { getStrapiContentByLang } from "../../heplers"
import { Pagination } from "@material-ui/lab"
import Seo from "@components/seo"

const styles = ({ palette }) => ({
  date: {
    color: palette.text.muted,
    fontSize: "14px"
  },
  title: {
    fontSize: "18px",
    marginBottom: 7
  },
  tips: {
    fontSize: "20px",
    fontWeight: 500
  },
  text_muted: {
    color: palette.text.muted
  },
  description: {
    fontSize: "14px",
    overflow: "hidden",
    textOverflow: "clip",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3
  },
  actionarea: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start"
  },
  pagination: {
    margin: "25px 0"
  },
  card__content: {
    width: "100%"
  }
})

function Index({ pageContext, data }) {
  const {
    date,
    title,
    tips,
    text_muted,
    description,
    actionarea,
    pagination,
    card__content
  } = makeComponentStyles(styles)

  const { language } = useContext(IntlContextConsumer)
  const intl = useIntl()
  const Categories = pageContext.payloadCategories.map(node => ({
    id: node.id,
    node: getStrapiContentByLang(node.nodes, language)
  }))
  const Playbills = pageContext.payloadPlaybills.map(node => ({
    id: node.id,
    node: getStrapiContentByLang(node.nodes, language)
  }))

  const [sorted, setSorted] = useState(Playbills)
  const [picker, setPicker] = useState(Date.now())
  const [datevalue, setDateValue] = useState("")
  const [selectedCategories, setSelectedCategories] = useState([])

  useEffect(() => {
    setSorted(Playbills.filter(key => key.node.published_at.includes(datevalue) && selectedCategories.every(e => key.node.categories.map(i => i.Name).includes(e))))
  }, [datevalue, selectedCategories])

  const ArrayHandleChange = (event, name) => {
    if (event.target.checked) {
      setSelectedCategories([...selectedCategories, name])
    } else {
      setSelectedCategories(selectedCategories.filter(i => i !== name))
    }
  }

  const DateValueHandleChange = (date) => {
    setPicker(date)
    setDateValue(
      ("0" + date?.getDate()).slice(-2) + "."
      + ("0" + (date?.getMonth() + 1)).slice(-2) + "."
      + date?.getFullYear())
  }

  const getItemsByPageNumber = number =>
    sorted.slice((number - 1) * pageSize, number * pageSize)

  const pageSize = 12
  const [page, setPage] = useState(1)
  const [currentItems, setCurrentItems] = useState(
    getItemsByPageNumber(1)
  )

  useEffect(() => {
    setPage(1)
    setCurrentItems(
      getItemsByPageNumber(1)
    )
  }, [sorted])

  const handlerPagination = (event, value) => {
    setPage(value)
    setCurrentItems(
      getItemsByPageNumber(value)
    )
  }

  const {
    PlaybillsSEO
  } = getStrapiContentByLang(data.allStrapiSettings.edges, intl.locale)

  return (
    <div>
      <Seo title={PlaybillsSEO?.TitleSEO}
           description={PlaybillsSEO?.DescriptionSEO}
           keywords={PlaybillsSEO?.KeywordsSEO.split(",")}
      />
      <Layout>
        <Container>
          <MainWrap>
            <MainWrap.Header>
              <Typography component={"h1"} variant={"h2"}>{intl.formatMessage({ id: "playbills" })}</Typography>

              <Grid container style={{ paddingTop: "45px" }}>
                <Grid item xs={6} md={4} style={{ paddingRight: "20px" }}>
                  <Typography className={tips}>{intl.formatMessage({ id: "select_date" })}:</Typography>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      style={{ paddingTop: "9px" }}
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      format="dd.MM.yyyy"
                      value={picker}
                      onChange={DateValueHandleChange}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} md={8}>
                  <Typography className={tips}>{intl.formatMessage({ id: "categories" })}:</Typography>
                  <Grid container>
                    {
                      Categories.map(({ node }) => (
                        <Grid item xs={12} md={3}>
                          <FormControlLabel
                            label={node.Name}
                            control={
                              <Checkbox
                                onChange={(event) => ArrayHandleChange(event, node.Name)}
                                color="primary"
                              />
                            }
                          />
                        </Grid>
                      ))
                    }
                  </Grid>
                </Grid>
              </Grid>

            </MainWrap.Header>
            <MainWrap.Body>
              <Grid container spacing={2}>
                {
                  currentItems.map(({ node }) => (
                    <Grid item md={4} xs={12}>
                      <Card style={{ height: "100%" }}>
                        <CardActionArea className={actionarea} onClick={() => navigate(`/playbills/${node.strapiId}`)}>
                          <CardMedia
                            component="img"
                            height="250"
                            image={node.Image.localFile.publicURL}
                            alt={node.Image.name}
                          />
                          <CardContent className={card__content}>
                            <Typography className={date}>{node.published_at}</Typography>
                            <Typography className={title}>{node.Title}</Typography>
                            {
                              node.categories.map(node => (
                                <Chip label={node.Name} style={{ margin: "0 20px 12px 0" }} />
                              ))
                            }
                            <Typography className={description}>{node.ShortDescription}</Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))
                }
              </Grid>
              {!currentItems.length
                ? (
                  <>
                    <Typography variant={"h4"}
                                className={text_muted}>{intl.formatMessage({ id: "nodata" })}</Typography>
                    <Typography className={text_muted}>{intl.formatMessage({ id: "tryanotherfilters" })}</Typography>
                  </>
                ) : null
              }
              <Pagination count={Math.ceil(sorted.length / pageSize)}
                          page={page}
                          onChange={handlerPagination}
                          color={"primary"}
                          className={pagination}
              />
            </MainWrap.Body>
          </MainWrap>
        </Container>
      </Layout>
    </div>
  )
}

export default Index

export const pageQuery = graphql`{
  allStrapiSettings {
    edges {
      node {
        PlaybillsSEO {
          KeywordsSEO
          TitleSEO
          DescriptionSEO
        }
        locale
      }
    }
  }
}`
